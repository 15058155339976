<template>
  <div class="avatar">
    <template v-if="!token">
      <NuxtLink
        v-if="!nav || nav.userLogin"
        :to="'/login?redirect=' + encodeURIComponent(routePath)"
        class="handle_bar_item login"
      >
        <span> 登录 </span>
      </NuxtLink>
      <NuxtLink
        v-if="
          nav &&
          nav.userRegister &&
          userStore.shopData &&
          userStore.shopData.config?.allowUserRegister
        "
        to="/register"
        class="handle_bar_item"
      >
        <span> 注册 </span>
      </NuxtLink>
      <NuxtLink
        v-if="
          nav &&
          nav.userRegister &&
          userStore.shopData &&
          userStore.shopData.config?.allowUserRegister &&
          purchaseSwitch
        "
        to="/account/order"
        class="order"
      >
        <span> 我的订单 </span>
      </NuxtLink>
    </template>
    <client-only v-if="token">
      <NuxtLink
        v-if="
          !userView.isVip &&
          nav &&
          nav.vip &&
          userStore.shopData?.config.vipEnableMarketing
        "
        to="/vip"
        class="handle_bar_item"
      >
        <span> 开通VIP </span>
      </NuxtLink>
      <NuxtLink to="/account/message" class="handle_bar_item bell">
        <el-icon :size="20"><Bell /></el-icon>
        <div class="messageCount" v-if="data.count == 0 ? '' : data.count">
          <div class="messageNum">{{ data.count }}</div>
        </div>
      </NuxtLink>
      <el-popover
        key="head_popover"
        placement="bottom"
        trigger="hover"
        :teleported="false"
        popper-class="nav_popover"
        :width="310"
      >
        <template #reference>
          <NuxtLink to="/account/course" class="handle_bar_item">
            <el-avatar class="avatar_img" :size="30" :src="userView.headImg">
              <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              />
            </el-avatar>
          </NuxtLink>
        </template>
        <div class="user_info_box">
          <div class="user_info">
            <NuxtLink to="/account/info">
              <el-avatar class="user_info_avatar_img" :size="60" :src="userView.headImg">
                <img
                  src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                />
              </el-avatar>
            </NuxtLink>
            <h5 class="user_info_name">
              {{ userView.nickname }}
            </h5>
            <p class="user_info_phone">
              {{
                userView.phone
                  ? userView.phone.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2")
                  : ""
              }}
            </p>
            <span class="user_info_expirationDate" v-if="userView.expirationDate">
              (到期时间：{{ userView.expirationDate.slice(0, 10) }})
            </span>
          </div>
          <ul class="user_btn_list">
            <NuxtLink to="/account/course" class="user_btn">
              <li>
                <i class="iconfont icon-icon_kecheng" />
                我的课程
              </li>
            </NuxtLink>
            <NuxtLink to="/account/note" class="user_btn">
              <li>
                <i class="iconfont icon-icon_Edit" />
                我的笔记
              </li>
            </NuxtLink>
            <NuxtLink to="/account/certificate" class="user_btn">
              <li>
                <i class="iconfont icon-icon_Certificate" />
                我的证书
              </li>
            </NuxtLink>
            <NuxtLink to="/account/order" class="user_btn" v-if="false">
              <li>
                <i class="iconfont icon-dingdan" />
                我的订单
              </li>
            </NuxtLink>
          </ul>
          <button class="out_btn" @click="userOut()">
            <i class="iconfont icon-tuichu" />
            安全退出
          </button>
        </div>
      </el-popover>
      <NuxtLink
        v-if="
          purchaseSwitch &&
          route.path !== '/course/single' &&
          route.path !== '/course/combine'
        "
        to="/account/order"
        class="order"
      >
        <span> 我的订单 </span>
      </NuxtLink>
    </client-only>
  </div>
</template>

<script setup>
import { useNavStore } from "@/stores/nav";
import { useUserStore } from "@/stores/user";
import { setSession } from "@/utils/storage";
import { Bell } from "@element-plus/icons-vue";
import { useMsgCount } from "@/api/user";
const route = useRoute();
const router = useRouter();
const store = useNavStore();
const userStore = useUserStore();
const { setToken, setUserView, shopData } = userStore;

const purchaseSwitch = computed(() => {
  const priceSwitch = shopData?.config?.purchaseSwitch;
  return priceSwitch == undefined || priceSwitch == null ? true : priceSwitch;
});
userStore.getUserView();
const nav = store.nav;
const token = computed(() => userStore.token);
const userView = computed(() => userStore.userView);
const routePath = ref(route.fullPath);
const data = reactive({
  count: 0,
});

router.beforeResolve((to) => {
  routePath.value = to.fullPath;
});

onMounted(async () => {
  await getCount();
});

const userOut = () => {
  setToken("");
  setUserView("");
  setSession("userInfo", "");
  window.location.href = "/";
};

const getCount = () => {
  if (token.value) {
    useMsgCount().then((res) => {
      data.count = Number(res);
    });
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-items: center;
  position: relative;
}
.login {
  margin-right: 20px;
}
.handle_bar_item {
  cursor: pointer;
  color: #333;
  // margin-right: 20px;
  white-space: nowrap;
  &:active {
    color: var(--el-color-primary);
  }
}
.handle_bar_item:last-child {
  margin-right: 0;
}
.order {
  position: absolute;
  right: -85px;
}

.bell {
  margin-top: 10px;
  margin-right: 20px;
  position: relative;
}

.messageCount {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #f61818;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -5px;

  .messageNum {
    font-size: 9px;
    color: #fff;
  }
}

.avatar_img {
  margin-top: 6px;
}

.user_info_box {
  width: 310px;
  height: 275px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(54, 41, 183, 0.06);
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  padding: 20px;

  .out_btn {
    margin-top: 15px;
    border-top: 1px solid #ebeef5;
    padding: 12px 0 16px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #f61818;
    .iconfont {
      font-size: 20px;
      vertical-align: -2.5px;
    }
  }

  .user_btn_list {
    font-size: 0px;
    margin-top: 16px;
    .user_btn {
      width: 130px;
      height: 50px;
      text-align: center;
      background: #f6f8fb;
      border-radius: 5px;
      display: inline-block;
      margin: 0 10px 10px 0;
      font-size: 14px;
      color: #333333;
      line-height: 50px;

      &:nth-child(2n) {
        margin-right: 0px;
      }
      .iconfont {
        font-size: 20px;
        vertical-align: -3px;
      }
    }
  }
  .user_info {
    padding-left: 75px;
    position: relative;
    height: 60px;
    .user_info_avatar_img {
      position: absolute;
      left: 0;
      top: 0;
    }

    .user_info_name {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding-top: 8px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user_info_phone {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 6px;
      color: #333333;
    }
    .user_info_expirationDate {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      margin-top: 5px;
      color: #f56c6c;
      letter-spacing: 1px;
    }
  }
}
</style>
